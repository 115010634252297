<template>
  <div class="" style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class='col-md-8 d-flex flex-row align-center'>
          <v-btn text class="mx-2" @click="()=>{$router.go(-1)}">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <h1>{{singular}} #{{data.id}} <span v-if="data.poId">for Purchase Order #{{data.poId}}</span></h1>
          <v-progress-circular
              indeterminate
              color="green"
              v-if="loader"
              class="ml-2"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex flex-column">
          <span v-if="data.createdAt">Created By {{lookupUsername(data.createdBy)}} at {{utils.formatDate(data.createdAt, 'withTime')}}</span>
          <span v-if="data.sealedAt">Sealed By {{lookupUsername(data.sealedBy)}} at {{utils.formatDate(data.sealedAt, 'withTime')}}</span>
          <span v-if="data.voidedAt">Voided By {{lookupUsername(data.voidedBy)}} at {{utils.formatDate(data.voidedAt, 'withTime')}}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div>
            <v-btn color="error" @click="openVoidDialog()" :disabled="data.status===-1">Void</v-btn>
            <v-btn class="ml-2" color="success" @click="openSealDialog()" :disabled="!canSeal">Seal</v-btn>
            <v-btn v-if="data.poId" class="ml-2" color="info" @click="$router.push(`/purchaseorders/view/${data.poId}`)">Purchase Order <v-icon>mdi-arrow-right</v-icon></v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-2">
          <span>
            <v-autocomplete
                :items="suppliers"
                v-model="data.supplierId"
                item-text="name"
                item-value="id"
                :disabled="!canEdit || (data.poId && data.poId > 0)"
                outlined
                label="Supplier"
                clearable
                @change="updateSupplier"
            />
          </span>
          <span v-if="canEdit" class="d-flex flex-column">
            <h2>Add Item</h2>
            <v-card outlined>
              <v-card-text>
                <span class="d-flex flex-column">
                  <v-text-field :loading="searchLoading" clearable label="Search Products" v-model="productSearchTerm" dense outlined @change="searchProduct"></v-text-field>
                  <v-checkbox @change="updateSupplier" v-if="getGlobalValue('constrainPurchaseOrdersToSupplierItems')!='true'" style="margin-top: -20px;" label="Constrain search to selected supplier" v-model="data.metadata.constrainSearch"/>
                </span>
                <span>
                  <span v-if="productSearchResults.length>0" class="d-flex flex-row justify-center">
                    <b>Search Results</b>
                    <v-btn x-small color="info" style="margin-left: 10px;" @click="clearSearch">Clear</v-btn>
                  </span>
                  <div v-for="(item, i) in productSearchResults" :key="i">
                    <div class="d-flex flex-column justify-space-between" style="align-text: left; background-color: rgba(0,0,0,0.05); padding: 10px; margin-top: 6px; border-radius: 7px;">
                      <span class="d-flex flex-row justify-space-between">
                        <b style="text-align: left;">{{item.Brand?`${item.Brand.name} `:''}} {{item.name}}</b>
                        <v-btn :disabled="data.ReceivingReportLineItems.findIndex(x => x.productId===item.id)>=0" :loading="item.loading" x-small fab color="success" @click="addProductToRR(item)"><v-icon>mdi-plus</v-icon></v-btn>
                      </span>
                      <span  v-if="getGlobalValue('regularPriceFieldOnReceivingReports') && isAllowed('product', 'u')" class="d-flex flex-column align-start">
                        <span>RP: ${{item.regularPrice}}</span>
                      </span>
                      <span class="d-flex flex-row">
                        <router-link v-if="isAllowed('product', 'u')" :to="'/products/view/'+item.id">ID: {{item.id}}</router-link>
                        <span v-else>ID: {{item.id}}</span>
                        <span class="ml-2">SKU:{{item.sku}}</span>
                      </span>
                    </div>
                  </div>
                </span>
              </v-card-text>
            </v-card>
          </span>
        </v-col>
        <v-col style="background-color: rgba(0,0,0,0.05); border-radius: 10px; padding: 20px;">
          <div style="height: 100%;">
            <h2>Line Items</h2>
            <span class="red--text" v-if="getGlobalValue('costPriceFieldOnReceivingReports')==='true' && isAllowed('product', 'u') && data.status===0">Cost price can only be modified when a supplier is selected and only the selected supplier's price can be entered through this receiving report. If you wish to enter further information, please go to the product. A cost price of $0 is also created for that supplier on all the products where a cost price is not specified.</span>
            <span class="red--text" v-if="data.status===1">Voiding this report will not revert the regular price and cost price of an item if they were altered. This needs to be changed manually.</span>
            <div style="border: 2px solid rgba(0,0,0,0.15); border-radius: 10px; margin-top: 20px;">
              <!-- body -->
                <v-simple-table style="background-color: rgba(0,0,0,0)">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th style="width:100px;" class="text-left">
                        QTY
                      </th>
                      <th class="text-center">
                        ID
                      </th>
                      <th class="text-left">
                        Name
                      </th>
                      <th class="text-center">
                        SKU
                      </th>
                      <th v-if="getGlobalValue('regularPriceFieldOnReceivingReports') && isAllowed('product', 'u')" class="text-left">
                        Regular Price
                      </th>
                      <th v-if="getGlobalValue('costPriceFieldOnReceivingReports') && isAllowed('product', 'u')" class="text-left">
                        Cost Price
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in data.ReceivingReportLineItems" :style="i===data.ReceivingReportLineItems.length-1?'border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;':''" :key="i">
                      <td>
                        <v-text-field
                            outlined
                            class="mt-5"
                            style="width:100px; border-bottom-left-radius: inherit;"
                            dense
                            type="number"
                            :disabled="!canEdit"
                            :rules="quantityRules"
                            v-model="item.quantity"
                            min="1"
                            @change="updateLineItem(item.productId)">
                        </v-text-field>
                      </td>
                      <td class="text-center">
                        <router-link v-if="isAllowed('product', 'u') && item.productId != 'adhoc'" :to="'/products/view/'+item.productId">{{item.productId}}</router-link>
                        <span v-else>{{item.productId}}</span>
                      </td>
                      <td>{{item.name}}</td>
                      <td class="text-center">{{item.sku}}</td>
                      <td v-if="getGlobalValue('regularPriceFieldOnReceivingReports')==='true' && isAllowed('product', 'u')">
                        <v-text-field
                            outlined
                            class="mt-5"
                            style="width:100px; border-bottom-left-radius: inherit;"
                            dense
                            type="number"
                            :disabled="!canEdit"
                            :rules="priceRules"
                            v-model="item.metadata.regularPrice"
                            min="1"
                            @change="updateLineItem(item.productId)">
                        </v-text-field>
                      </td>
                      <td v-if="getGlobalValue('costPriceFieldOnReceivingReports')==='true' && isAllowed('product', 'u')">
                        <v-text-field
                            outlined
                            class="mt-5"
                            style="width:100px; border-bottom-left-radius: inherit;"
                            dense
                            type="number"
                            :disabled="!canEdit"
                            v-if="data.supplierId"
                            :rules="priceRules"
                            v-model="item.metadata.costPrice"
                            min="1"
                            @change="updateLineItem(item.productId)">
                        </v-text-field>
                      </td>
                      <td style="border-bottom-right-radius: inherit;">
                        <v-btn v-if="canEdit" :loading="item.loading" x-small fab color="error" @click="removeLineItem(item.productId)"><v-icon >mdi-close</v-icon></v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
        </v-col>
        <v-col cols="2">
          <span class="d-flex flex-column ml-3">
            <v-card outlined>
              <v-card-text>
                <v-text-field :loading="supplierInvoiceNumberLoading" label="Supplier Invoice Number" v-model="data.metadata.supplierExternalInvoiceId" dense outlined @change="saveSupplierInvoiceNumber"></v-text-field>
                <v-btn small color="success">Save</v-btn>
              </v-card-text>
            </v-card>
            <v-card outlined class="mt-3">
              <v-card-text>
                <span class="mt-5">
                  <v-file-input
                    outlined
                    dense
                    accept="image/*"
                    name="file"
                    show-size
                    :rules="supplierImageRules"
                    counter
                    label="Upload Invoice File"
                    @change="onAddImg"
                  ></v-file-input>
                  <v-btn :disabled="!imgFile || imgFile.size > parseInt(this.getGlobalValue('VEC_MAX_IMAGE_UPLOAD_SIZE'))" :loading="imageUploadLoader" color="info" block small @click="uploadSupplierInvoiceImage()">Upload</v-btn>
                </span>
                <span v-if="data.metadata && data.metadata.media && data.metadata.media.length > 0" class="mt-3">
                  <div v-for="(img,index) in data.metadata.media" :key="index" class=" mt-2 d-flex flex-row align-space-between justify-space-between" style="border-radius: 7px; border: 1px rgba(0,0,0,0.25) solid;">
                    <a :href="img.url" target="__blank">
                      <img :src="img.url" style="width: 100%; height: 300px; object-fit: contain;" alt="">
                    </a>
                    <confirmedActionButton style="margin-left: -32px;" color="error" xsmall fab text @cb="deleteSupplierInvoiceImage(img)" fabIcon="mdi-close" :dialogText="'Are you sure you wish to proceed?'" />
                  </div>
                </span>
              </v-card-text>
            </v-card>
          </span>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
        v-model="authDialog.sealOpen"
        max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Seal {{singular}}
        </v-card-title>
        <v-card-text>Please ensure all fields are correct. Your identity will be linked to this document.</v-card-text>
        <v-card-text>Enter your password to confirm.</v-card-text>
        <v-text-field label="Password" placeholdler="Password" type="password" outlined style="width: 300px; margin: 20px;" v-model="authDialog.password"></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="error"
              text
              @click="closeAuthDialog()"
          >
            Cancel
          </v-btn>
          <v-btn
              color="success"
              :loading="this.authDialog.loading"
              :disabled="!this.authDialog.password"
              @click="attemptSeal"
          >
            Confirm Signature
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="authDialog.voidOpen"
        max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Void {{singular}}
        </v-card-title>
        <v-card-text>Please ensure all fields are correct. Your identity will be linked to this document.</v-card-text>
        <v-card-text>Enter your password to confirm.</v-card-text>
        <v-text-field label="Password" placeholdler="Password" type="password" outlined style="width: 300px; margin: 20px;" v-model="authDialog.password"></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="error"
              text
              @click="closeAuthDialog()"
          >
            Cancel
          </v-btn>
          <v-btn
              color="success"
              :loading="this.authDialog.loading"
              :disabled="!this.authDialog.password"
              @click="attemptVoid"
          >
            Confirm Signature
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import utils from "../../plugins/helpers";
  import {mapGetters} from 'vuex'
  import confirmedActionButton from './../../components/confirmedActionButton.vue';
  export default {
    components: {
      confirmedActionButton
    },
    data () {
      return {
        utils: utils,
        loader: false,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },
        imageUploadLoader: false,
        imgFile: null,
        supplierInvoiceNumberLoading: false,
        supplierImageRules: [
          value => !value || value.size < parseInt(this.getGlobalValue('VEC_MAX_IMAGE_UPLOAD_SIZE')) || `Image size should be less than ${parseInt(this.getGlobalValue('VEC_MAX_IMAGE_UPLOAD_SIZE'))} Bytes`,
        ],

        max25chars: v => v.length <= 25 || 'Input too long!',
        quantityRules: [
          v => v>=1 || 'Must be more than or equal 1'
        ],
        priceRules: [
          v => v>=0 || 'Must be more than or equal 0'
        ],

        data: {
          ReceivingReportLineItems: [],
          metadata: {
            constrainSearch: false
          }
        },

        singular: "Receiving Report",
        singularLower: "receivingreport",
        plural: "Receiving Reports",
        pluralLower: "receivingreports",

        suppliers: [],

        authDialog: {
          sealOpen: false,
          voidOpen: false,
          password: '',
          loading: false,
        },

        productSearchResults: [],
        productSearchTerm: "",
        searchLoading: false
      }
    },
    async mounted(){
      await this.getAllData();
    },
    computed: {
      supplierDisabledCriteria(){
        return this.data.ReceivingReportLineItems.length>0
      },
      selectedSupplier(){
        if(this.suppliers.length>0){
          return this.suppliers.find(x=>x.id==this.purchaseOrder.supplierId)
        }
        return "-"
      },
      canSeal(){
        if(this.data.status!==0) return false;
        if(!this.data.ReceivingReportLineItems || this.data.ReceivingReportLineItems.length===0) return false;
        for(let item of this.data.ReceivingReportLineItems){
          if(item.quantity<=0 || isNaN(item.quantity)) return false;
        }
        return true
      },
      canEdit(){
        if(this.data.status===0) return true;
        return false;
      },
      ...mapGetters(['getWorkingId', 'getEndpoint', 'getGlobalValue', 'lookupUsername', 'isAllowed'])
    },
    methods: {
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async updateSupplier(){
        await this.update();
        await this.searchProduct();
      },
      onAddImg(img){
        this.imgFile = img
      },
      async uploadSupplierInvoiceImage(){
        try{
          this.imageUploadLoader = true;
          if(!this.imgFile) throw "No Image Selected.";

          let formData = new FormData();
          formData.append("file", this.imgFile);

          let fileUpload = await axios.post(`${this.getEndpoint}/api/receivingreports/uploadSupplierInvoiceImage/${this.data.id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          if(fileUpload.data.success){
            this.snack("✅ Image Uploaded.", "success")
            this.data.metadata.media = fileUpload.data.data;
            this.imgFile = null
          }
          else 
            throw "Error: Image was not deleted."

          console.log(fileUpload);
        }
        catch(e) {
          console.error(e);
        }
        finally {
          this.imageUploadLoader = false;
        }
      },
      async deleteSupplierInvoiceImage(img){
        try {
          this.loader = true;

          let res = await axios.post(`${this.getEndpoint}/api/receivingreports/deleteSupplierInvoiceImage`, {url: img.url, rrId: this.data.id})
          if(res.data.success){
            this.snack("✅ Image Deleted.", "success")
            this.data.metadata.media = res.data.data;
          }
          else 
            throw "Error: Image was not deleted."
        } catch (error) {
          this.snackText = error
          this.snackBar = true;
        } finally {
          this.loader = false
        }
      },
      async update(){
        try{
          this.loader = true;

          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/${this.data.id}`, this.data);
          if(res.data.error) throw res.data.error

          this.data = {
            ...this.data,
            ...res.data.data
          };
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
        finally {
          this.loader = false;
        }
      },
      async addProductToRR(item){
        try{
          item.loading = true;

          let obj = {
            rrId: this.data.id,
            productId: item.id,
            name: item.name,
            sku: item.sku,
            quantity: 1,
            metadata: {
              regularPrice: parseFloat(item.regularPrice || 0)
            }
          }

          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}/lineItem/${this.data.id}`, obj);
          if(res.data.error) throw res.data.error

          this.data.ReceivingReportLineItems.push({...res.data.data, loading: false});
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
        finally {
          item.loading = false;
        }
      },
      async removeLineItem(id){
        try{
          let item = this.data.ReceivingReportLineItems.find(x => x.productId===id);
          if(!item) throw "Product not in report";

          item.loading = true;
          this.data = {
            ...this.data,
            ReceivingReportLineItems: this.data.ReceivingReportLineItems
          }

          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/deleteLineItem/${this.data.id}`, item);
          if(res.data.error) throw res.data.error

          let i = this.data.ReceivingReportLineItems.findIndex(x => x.productId===id);

          this.data.ReceivingReportLineItems.splice(i, 1);
        }
        catch (error) {
          let item = this.data.ReceivingReportLineItems.find(x => x.productId===id);

          item.loading = false;
          this.data = {
            ...this.data,
            ReceivingReportLineItems: this.data.ReceivingReportLineItems
          }

          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      async updateLineItem(id){
        try{
          this.loader = true;

          let item = this.data.ReceivingReportLineItems.find(x => x.productId===id);
          if(!item) throw "Product not in report";

          if(item.quantity<1) {
            item.quantity = 1;
            throw "Quantity must be greater than 0."
          }

          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/lineItem/${this.data.id}`, item);
          if(res.data.error) throw res.data.error

          item = res.data.data;
          this.data = {
            ...this.data,
            ReceivingReportLineItems: this.data.ReceivingReportLineItems
          }
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
        finally {
          this.loader = false;
        }
      },
      async searchProduct(){
        try {
          if(this.productSearchTerm){
            this.searchLoading = true;
            let res = await axios.get(`${this.getEndpoint}/api/products/searchByAllVariationsNoLimitForPO?val=${encodeURIComponent(this.productSearchTerm)}&constrain=${encodeURIComponent(this.data.metadata.constrainSearch)}&supplierId=${encodeURIComponent(this.data.supplierId)}`);
            if(res.data.error) throw res.data.error

            let products = res.data.data;

            for(let prod of products){

              prod.loading = false;
            }
            this.productSearchResults = products

            if(products.length===0) throw "No products matching the search criteria."
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }finally {
          this.searchLoading = false;
        }
      },
      clearSearch(){
        this.productSearchResults = [];
      },
      openSealDialog(){
        this.authDialog.voidOpen = false;
        this.authDialog.sealOpen = true;
        this.authDialog.loading = false
        this.authDialog.password = ''
      },
      openVoidDialog(){
        this.authDialog.sealOpen = false;
        this.authDialog.voidOpen = true;
        this.authDialog.loading = false
        this.authDialog.password = ''
      },
      closeAuthDialog(){
        this.authDialog.voidOpen = false;
        this.authDialog.sealOpen = false;
        this.authDialog.password = ''
      },
      async attemptVoid(){
        try{
          this.authDialog.loading = true
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/void/${this.data.id}`, {...this.authDialog})
          if(res.data.error) throw res.data.error

          await this.getAllData();

          this.snack(`✅ Voided.`)
          this.closeAuthDialog();
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
        finally {
          this.authDialog.loading = false
        }
      },
      async attemptSeal(){
        try{
          this.authDialog.loading = true
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/seal/${this.data.id}`, {...this.authDialog})
          if(res.data.error) throw res.data.error

          await this.getAllData();

          this.snack(`✅ Sealed.`)
          this.closeAuthDialog();
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
        finally {
          this.authDialog.loading = false
        }
      },
      async saveSupplierInvoiceNumber(){
        try{
          this.supplierInvoiceNumberLoading = true
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/supplierInvoiceNumber/${this.data.id}`, {invoiceNumber: this.data.metadata.supplierExternalInvoiceId})
          if(res.data.error) throw res.data.error

          this.snack(`✅ Supplier Invoice Number Saved.`)
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
        finally {
          this.supplierInvoiceNumberLoading = false
        }
      },
      async getAllData(){
        try{
          this.loader = true;

          let rr = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`);
          if(rr.data.error) throw rr.data.error
          this.data = rr.data.data

          this.data.ReceivingReportLineItems.map(x => {
            return {...x, loading: false}
          });

          let suppliers = await axios.get(`${this.getEndpoint}/api/suppliers`);
          if(suppliers.data.error) throw suppliers.data.error
          this.suppliers = suppliers.data.data

          this.data.metadata.constrainSearch = this.getGlobalValue("constrainPurchaseOrdersToSupplierItems")==="true";
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
        finally {
          this.loader = false;
        }
      }
    }
  }
</script>
